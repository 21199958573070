<template>
  <div>
    <h4>CONFIGURACION DE STOCK</h4>
    <app-table-registers ref="tr" :getList="getList">
      <template slot="tl">
        <div class="form-inline col">
          <button
            class="btn btn-primary mr-1"
            @click="
              $refs.dForm.show();
              $refs.form.reset();
            "
          >
            <i class="fa fa-plus"></i> Nuevo
          </button>
          <SelectStore v-model="store_id" class="w-50"></SelectStore>
        </div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>COD.</th>
            <th>PRODUCTO</th>
            <th>ALMACEN</th>
            <th>U. X PAQ.</th>
            <th>STOCK</th>
            <th>MINIMO</th>
            <!-- <th></th> -->
            <!-- <th>DURACION(DIAS)</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <td>{{ l.product_id }} </td>
            <td>{{ l.product_name }}</td>
            <td>{{ l.store_name }}</td>
            <td>{{ l.product_units_by_pack }}</td>
            <td>{{ l.quantity }}</td>
            <td>{{ l.stock_min }}</td>
            <!-- <td>{{ l.stock_per }} </td> -->
            <!-- <td>{{ l.duration }}</td> -->
            <td>
              <button
                class="btn btn-light btn-sm"
                @click="
                  $refs.dForm.show();
                  $refs.form.loadReg(l);
                "
              >
                <i class="fa fa-edit"></i>
              </button>
              <!-- <button class="btn btn-light btn-sm" @click=""><i class="fa fa-trash"></i> </button> -->
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dForm">
      <Form
        ref="form"
        @submitted="
          $refs.dForm.hide();
          $refs.tr.gl();
        "
      ></Form>
    </app-modal-basic>

    <!-- <app-modal-yn ref="dDelConf">
      <p>Eliminar la configuracion de stock </p>
    </app-modal-yn>-->
  </div>
</template>

<script>
import { LogisticService } from "../service";
import Form from "./Form";
import SelectStore from "../stores/Select";

export default {
  components: {
    Form,
    SelectStore
  },

  data: () => ({
    list: {},
    store_id: undefined
  }),
  watch: {
    store_id() {
      this.$refs.tr.gl();
    }
  },
  methods: {
    getList({ page, search }) {
      return new Promise(rsv => {
        LogisticService.getStockConfig({
          page,
          search,
          store_id: this.store_id
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
